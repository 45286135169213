.shepherd-button {
    background: #0ab39c;
    border: 0;
    border-radius: 3px;
    color: hsla(0, 0%, 100%, .75);
    cursor: pointer;
    margin-right: .5rem;
    padding: .5rem 1.5rem;
    transition: all .5s ease
}

.is-invalid {
    border: 1px solid #f06548 !important;
    border-radius: 4px !important;
}

.shepherd-button:not(:disabled):hover {
    background: #196fcc;
    color: hsla(0, 0%, 100%, .75)
}

.shepherd-button.shepherd-button-secondary {
    background: #f1f2f3;
    color: rgba(0, 0, 0, .75)
}

.shepherd-button.shepherd-button-secondary:not(:disabled):hover {
    background: #d6d9db;
    color: rgba(0, 0, 0, .75)
}

.shepherd-button:disabled {
    cursor: not-allowed
}

.shepherd-footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: flex-end;
    padding: 0 .75rem .75rem
}

.shepherd-footer .shepherd-button:last-child {
    margin-right: 0
}

.shepherd-cancel-icon {
    background: transparent;
    border: none;
    color: hsla(0, 0%, 50.2%, .75);
    font-size: 2em;
    cursor: pointer;
    font-weight: 400;
    margin: 0;
    padding: 0;
    transition: color .5s ease
}

.shepherd-cancel-icon:hover {
    color: rgba(0, 0, 0, .75)
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
    color: hsla(0, 0%, 50.2%, .75)
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover {
    color: rgba(0, 0, 0, .75)
}

.shepherd-title {
    color: rgba(0, 0, 0, .75);
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    flex: 1 0 auto;
    margin: 0;
    padding: 0
}

.shepherd-header {
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    justify-content: flex-end;
    line-height: 2em;
    padding: .75rem .75rem 0
}

.shepherd-has-title .shepherd-content .shepherd-header {
    background: #e6e6e6;
    padding: 1em
}

.shepherd-text {
    color: rgba(0, 0, 0, .75);
    font-size: 1rem;
    line-height: 1.3em;
    padding: .75em
}

.shepherd-text p {
    margin-top: 0
}

.shepherd-text p:last-child {
    margin-bottom: 0
}

.shepherd-content {
    border-radius: 5px;
    outline: none;
    padding: 0
}

.shepherd-element {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
    max-width: 400px;
    opacity: 0;
    outline: none;
    transition: opacity .3s, visibility .3s;
    visibility: hidden;
    width: 100%;
    z-index: 9999
}

.shepherd-enabled.shepherd-element {
    opacity: 1;
    visibility: visible
}

.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
    opacity: 0;
    pointer-events: none;
    visibility: hidden
}

.shepherd-element,
.shepherd-element *,
.shepherd-element :after,
.shepherd-element :before {
    box-sizing: border-box
}

.shepherd-arrow,
.shepherd-arrow:before {
    position: absolute;
    width: 16px;
    height: 16px;
    z-index: -1
}

.shepherd-arrow:before {
    content: "";
    transform: rotate(45deg);
    background: #fff
}

.shepherd-element[data-popper-placement^=top]>.shepherd-arrow {
    bottom: -8px
}

.shepherd-element[data-popper-placement^=bottom]>.shepherd-arrow {
    top: -8px
}

.shepherd-element[data-popper-placement^=left]>.shepherd-arrow {
    right: -8px
}

.shepherd-element[data-popper-placement^=right]>.shepherd-arrow {
    left: -8px
}

.shepherd-element.shepherd-centered>.shepherd-arrow {
    opacity: 0
}

.shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before {
    background-color: #e6e6e6
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
    pointer-events: none
}

.shepherd-modal-overlay-container {
    height: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: all .3s ease-out, height 0ms .3s, opacity .3s 0ms;
    width: 100vw;
    z-index: 9997
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
    height: 100vh;
    opacity: .5;
    transition: all .3s ease-out, height 0s 0s, opacity .3s 0s
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
    pointer-events: all
}

.error-message-drop {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: var(--vz-form-invalid-color);
}

.word-wrap-custom {
    max-width: 200px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.spinner {
    margin-top: 36px;
}

.read-more {
    cursor: pointer;
    margin-left: 15px;
}

.button-disabled {
    border: none !important;
    background: none !important;
    padding: 0 !important;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.disabled-input {
    background-color: #eff2f7 !important;
    border-color: #ced4da !important;
}

.disabled-input-product-purchase {
    background-color: #f2f2f2 !important;
    border-color: #ced4da !important;
    color: rgb(148, 148, 148) !important;
}

.disabled-text {
    color: rgb(148, 148, 148) !important;
}

.loader-blur {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(255, 255, 255, 0.5) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    z-index: 999 !important;
}

.tagsWrap {
    display: flex !important;
    flex-wrap: wrap !important;
}

.imageDivWrap {
    max-width: '50px' !important;
    max-height: '50px' !important;
}

.imageWrap {
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: cover !important;
}

.simpleBarSearch {
    width: 225px !important;
}

.word-break {
    word-break: break-word !important;
}

.reportSubmit {
    height: 38px !important;
    margin-top: 1.5rem !important;
}

.buttonReport {
    margin-bottom: 1rem !important;
}

.table-responsive-scroll {
    max-height: 397px !important;
    overflow-y: auto !important;
}

.table-responsive.custom-table {
    overflow-x: auto !important;
}

.table-responsive.custom-table table {
    min-width: 1000px !important;
    /* Adjust based on your table's total width */
}

.table-responsive.custom-table table th,
.table-responsive.custom-table table td {
    min-width: 100px !important;
    /* Adjust based on your column's desired width */
    white-space: nowrap !important;
}

.tooltip-container {
    padding-top: 1em;
    position: relative !important;
    display: inline-block !important;
    cursor: pointer !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible !important;
}

.tooltip-text {
    visibility: hidden !important;
    width: auto !important;
    background-color: #fff !important;
    color: #000 !important;
    text-align: center !important;
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
    padding: 10px !important;
    position: absolute !important;
    z-index: 1 !important;
    bottom: 125% !important;
    /* Adjust as needed */
    left: 50% !important;
    margin-left: -100px !important;
    /* Adjust as needed */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    white-space: normal !important;
    max-width: 300px !important;
    /* Adjust as needed */
}

.sticky-top {
    position: sticky !important;
    top: 0 !important;
    z-index: 1 !important;
    background-color: #fff !important;
}

.dynamicColumnWidth {
    width: 5rem !important;
}

.dropZoneMobile {
    border: '2px dashed #e2e5ec' !important;
    padding: '20px' !important;
    border-radius: '5px' !important;
    cursor: 'pointer' !important;
}
.category-modal{
   height: 400px !important;
   overflow-y: auto !important;
}
.variant-details{
    margin-left: 1px !important;
    margin-right: 1px !important;
}
.tooltip-wrap {
    max-width: "300px" !important;
    word-wrap: break-word !important;
    white-space: normal !important;
}